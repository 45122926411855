@import "src/assets/scss/abstract";




































































































































:root {
  --ck-color-base-border: #{$border-color};
  --ck-border-radius: #{$border-radius};
  --ck-focus-ring: #{$border-width} solid #{$input-focus-border-color};
}

.ck {
  &.ck-toolbar,
  &.ck-editor__editable_inline {
    border-width: $border-width;
  }

  .is-invalid + & {
    .ck-toolbar,
    .ck-editor__editable_inline.ck-editor__editable_inline {
      border-color: $form-feedback-invalid-color;
    }
  }

  .ck-content {
    min-height: rem(240);
  }

  .ck-read-only.ck-read-only {
    background: $input-disabled-bg;
  }
}
